html {
  font-family: Roboto, sans-serif;
  color: #1e242f;
}

body {
  margin: 0;
}

h1 {
  margin-block-start: 1.25rem;
  margin-block-end: 1.25rem;
  font-weight: 900;
  color: #1e242f;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
input {
  font-family: Roboto, sans-serif;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.noscroll {
  overflow: hidden;
}

.text-secondary {
  color: #31acbf;
}

.min-full-width {
  min-width: 100%;
}

.min-full-height {
  min-height: 150px;
}

.right-0 {
  right: 0;
}

.url-ani-1 {
  text-underline-offset: 1px;
  transition: background-size 0.25s cubic-bezier(0.32, 0.74, 0.57, 1);
  background-image: linear-gradient(#31acbf, #31acbf);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0%;
}

.url-ani-3 {
  text-underline-offset: 1px;
  transition: background-size 0.25s cubic-bezier(0.32, 0.74, 0.57, 1);
  background-image: linear-gradient(#8ad5e1, #8ad5e1);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0%;
}

.url-ani-3:hover,
.url-ani-1:hover {
  background-size: 100% 30%;
}

.url-ani-2 {
  transition: all 0.3s ease-out;
}

.url-ani-2:hover {
  color: #31acbf;
}

.nav-item-header {
  text-decoration: none;
  font-weight: 700;
  color: #1e242f;
  padding: 5px 10px;
  border-radius: 4px;
  outline-style: none;
  outline-color: #ffffff00;
  transition: all 0.3s ease-out;
}

.nav-item {
  text-decoration: none;
  font-weight: 700;
  color: #1e242f;
  padding: 5px 20px;
  border-radius: 4px;
  outline-style: none;
  outline-color: #ffffff00;
  transition: all 0.3s ease-out;
}

.nav-item-overview {
  text-decoration: none;
  font-weight: 700;
  padding: 5px 20px;
  outline-style: none;
  outline-color: transparent;
  transition: all 0.3s ease-out;
}

.nav-sub-item {
  text-decoration: none;
  font-weight: 700;
  color: #1e242f;
  padding: 10px 20px 7px;
  border-radius: 4px;
  outline-style: none;
  outline-color: transparent;
  transition: all 0.3s ease-out;
  border-bottom: 3px solid transparent;
}

.nav-sub-item:hover {
  border-radius: 0px;
  border-bottom: 3px solid #ffc800;
}

.nav-ani {
  transition: all 0.3s ease-out;
}

.nav-ani:hover {
  background-color: transparent;
}

.nav-ani-tab {
  transition: all 0.3s ease-out;
  border: 1px solid #edeced;
  border-radius: 4px;
  padding: 8px 16px;
}

.nav-ani-tab-selected {
  transition: all 0.3s ease-out;
  background-color: #ffc800;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 16px;
  border: 1px solid transparent;
}

.nav-ani-selected {
  transition: all 0.3s ease-out;
  background-color: #ffc800;
  cursor: pointer;
}

.nav-ani-overview {
  transition: all 0.3s ease-out;
  border-bottom: 3px solid transparent;
}

.nav-ani-overview:hover {
  background-color: transparent;
  border-bottom: 3px solid transparent;
}

.nav-ani-overview-selected {
  transition: all 0.3s ease-out;
  border-bottom: 3px solid #ffc800;
  cursor: default;
}

.nav-ani-selected-2 {
  color: #31acbf;
  background-color: transparent;
  cursor: default;
}

.nav-ani-selected-3 {
  border-bottom: 3px solid #ffc800;
  border-radius: 0px;
  background-color: transparent;
  cursor: default;
}

.body-wrapper {
  min-height: 70vh;
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  padding-top: 20px;
}

.text-xs {
  font-size: 0.85rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-sm-unchanged {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.custom-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-responsive-md {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.313rem;
}

.custom-text-xl {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 2.625rem;
  line-height: 2.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 43px;
  line-height: 47.773px;
}

.badge {
  background-color: #ffc800;
  color: #1e242f;
  padding: 4px 8px;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
}

.grid {
  --gap: 2rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 1px;
  --line-color: #88888826;
  --line-length: 100%;

  display: grid;
  overflow: hidden;
  gap: var(--gap);
}

.list {
  --gap: 2rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 1px;
  --line-color: #88888826;
  --line-length: 100%;

  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  overflow: hidden;
  gap: var(--gap);
  align-items: flex-start;
  padding-block: 1rem;
}

.grid-2-col {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-3-col {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.custom-grid-3-col {
  grid-template-columns: 1.3fr 0.9fr 0.8fr;
  grid-template-rows: 1fr;
}

.grid-4-col {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-5-col {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.gap-30 {
  gap: 2rem;
}

.list-item,
.grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.list-border::before,
.list-border::after,
.grid-border::before,
.grid-border::after,
.custom-list-border::after,
.custom-list-border::before,
.custom-grid-border::after,
.custom-grid-border::before,
.desktop-list-border-only::after,
.desktop-list-border-only::before {
  content: '';
  position: absolute;
  background-color: var(--line-color);
  z-index: 1;
}

.custom-list-border::after,
.custom-grid-border::after {
  display: none;
}

.list-border::after,
.grid-border::after,
.desktop-list-border-only::after {
  inline-size: var(--line-length);
  block-size: var(--line-thickness);
  inset-inline-start: calc((100% - var(--line-length)) / 2);
  inset-block-start: calc(var(--line-offset) * -1);
}

.list-border::before,
.grid-border::before,
.desktop-list-border-only::before {
  --line-length: 100%;
  inline-size: var(--line-thickness);
  block-size: var(--line-length);
  inset-inline-start: calc(var(--line-offset) * -1);
}

.custom-list-border::before,
.custom-grid-border::before {
  --line-length: 100%;
  inline-size: var(--line-thickness);
  block-size: var(--line-length);
  inset-inline-start: calc(var(--line-offset) * -1);
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

.columns:last-child {
  margin-bottom: -0.75rem;
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.75rem);
}

.border-top {
  border-top: 1px solid #edeced;
}

.dropdown {
  display: inline-flex;
  position: relative;
  vertical-align: top;
}

.dropdown.is-active .dropdown-menu,
.dropdown.is-hoverable:hover .dropdown-menu {
  display: block;
}

.dropdown.is-right .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown.is-up .dropdown-menu {
  bottom: 100%;
  padding-bottom: 4px;
  padding-top: initial;
  top: auto;
}

.dropdown-menu {
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  z-index: 20;
}

.dropdown-content {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.dropdown-item {
  color: #4a4a4a;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
}

a.dropdown-item,
button.dropdown-item {
  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

a.dropdown-item:hover,
button.dropdown-item:hover {
  background-color: whitesmoke;
  color: #0a0a0a;
}

a.dropdown-item.is-active,
button.dropdown-item.is-active {
  background-color: #485fc7;
  color: #fff;
}

.dropdown-divider {
  background-color: #ededed;
  border: none;
  display: block;
  height: 1px;
  margin: 0.5rem 0;
}

.button {
  background-color: white;
  border: 0px;
  cursor: pointer;
  justify-content: center;
  padding: 4px 8px;
  text-align: center;
  white-space: nowrap;
}

.button:hover {
  background-color: rgb(247, 247, 247);
}

.custom-select {
  position: relative;
  min-width: 66px;
  color: #000;
  padding: 14px 0;
}

.custom-icon-select {
  position: relative;
  color: #000;
  padding: 14px 0;
}

.select-button {
  width: 100%;
  padding: 6px 10px;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  gap: 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button {
  width: fit-content;
  padding: 6px 10px;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  gap: 0.5rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.select-button:hover {
  transition: all 0.5s ease-in-out;
  background-color: #ededed;
}

.selected-value {
  text-align: left;
  font-weight: 700;
}

.arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000;
  transition: transform ease-in-out 0.3s;
}

.select-dropdown {
  min-width: 50px;
  position: absolute;
  list-style: none;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  top: -9999px;
  z-index: 9999;
  padding: 0px;
  margin-top: 20px;
  overflow-y: auto;
  transition: top 0s, opacity 0.32s cubic-bezier(0.32, 0.74, 0.57, 1),
    margin 0.5s cubic-bezier(0.32, 0.74, 0.57, 1);

  opacity: 0;
  visibility: hidden;
}

.select-dropdown li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.select-dropdown li label {
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.select-dropdown::-webkit-scrollbar {
  width: 7px;
}
.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}

.select-dropdown li:hover,
.select-dropdown input:checked ~ label {
  background-color: #f2f2f2;
}

.select-dropdown input:focus ~ label {
  background-color: #dfdfdf;
}

.select-dropdown input[type='radio'] {
  position: absolute;
  left: 0;
  opacity: 0;
}

.custom-select:hover .arrow,
.custom-select.active .arrow {
  transform: rotate(-180deg);
}

.custom-icon-select:hover .select-dropdown,
.custom-icon-select.active .select-dropdown,
.custom-select:hover .select-dropdown,
.custom-select.active .select-dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: 85%;
}

.swiper-pagination-bullet {
  background-color: #e9ecf3;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #31acbf;
  opacity: 1;
}

.pattern-dot:before {
  --start-position: -3%;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--start-position);
  width: calc(100% - 2 * var(--start-position));
  height: 100%;
  content: '';
  pointer-events: none;
  opacity: 0.15;

  background-image: radial-gradient(#000000 1px, transparent 1px);
  background-size: 15px 15px;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: hsla(180, 5%, 4%, 0);
  height: 100%;
  width: 100%;
  z-index: 998;
}

.youtube-button:hover {
  svg {
    fill: red;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: rgb(209, 209, 209);
  }
  100% {
    background-color: rgb(243, 243, 243);
  }
}

.skeleton-title-text {
  width: 100%;
  height: 1rem;
  margin-bottom: 0.3rem;
  border-radius: 0.25rem;
  color: transparent;
}

.skeleton-text-trasparent {
  color: transparent;
}

.skeleton-description-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.3rem;
  border-radius: 0.25rem;
  color: transparent;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
  line-height: 1.169rem;
  height: 1rem;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f2f2f299;
  height: 100%;
  width: 100%;
  z-index: 998;
}

.option-style {
  display: flex;
  padding: 6px 16px;
  border-radius: 30px;
  border: 1px solid #a8b5c9;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s background, 0.3s color, 0.3s border;
}

.option-style:hover {
  background: #e3f5f8;
  border: 1px solid #31acbf;
  color: #1e242f;
}

.option-style.active {
  border: 1px solid #31acbf;
  background: #e3f5f8;
}

.tag-style {
  display: flex;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 23px;
  background: #e9ecf3;
  border: 1px solid #e9ecf3;
  cursor: pointer;
  color: #374255;
  font-size: 12px;
  font-weight: 500;
  transition: 0.3s background, 0.3s color, 0.3s border;
}

.tag-style.active {
  background: #31acbf;
  color: #fff;
}

.tag-style:hover {
  background: #31acbf;
  border: 1px solid #31acbf;
  color: #fff;
}

.table-width-15 {
  width: 15%;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.mobile-collapse {
  display: none;
}

.collapse-activated {
  display: none;
}

.post-description p {
  font-size: 16px;
  margin-block-start: 0;
  margin-block-end: 1rem;
  line-height: 1.5rem;
}

.post-description img {
  max-width: 100%;
  height: auto;
}

.post-description a {
  text-decoration: none;
}

// .post-description iframe {
//   width: 100%;
// }

.post-description ul {
  padding-left: 15px;
}

.post-description h2,
.post-description h3,
.post-description h4,
.post-description h5,
.post-description h6 {
  margin-block-start: 0;
  margin-block-end: 0.3em;
  font-weight: 400;
  line-height: 1.1;
}

.post-description h6 {
  font-size: 14px;
  line-height: 18px;
}

.table,
.text-wrap table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.table th,
.text-wrap table td,
.text-wrap table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e8ebf3;
}

.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
}

.ico-listing.table td,
.ico-listing.table th {
  padding: 0.75rem;
  vertical-align: middle;
}

.sticky-wrapper {
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
}

.border {
  padding: 0.3rem;
  vertical-align: top;
  border: 1px solid #e8ebf3;
}

.table thead th,
.text-wrap table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8ebf3;
}

.table tbody + tbody,
.text-wrap table tbody + tbody {
  border-top: 2px solid #e8ebf3;
}

.table .table,
.table .text-wrap table,
.text-wrap .table table,
.text-wrap table .table,
.text-wrap table table {
  background-color: #e9ecf3;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.text-wrap table {
  border: 1px solid #e8ebf3;
}

.table-bordered td,
.table-bordered th,
.text-wrap table td,
.text-wrap table th {
  border: 1px solid #e8ebf3;
}

.table-bordered thead td,
.table-bordered thead th,
.text-wrap table thead td,
.text-wrap table thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.table-hover tbody tr:hover {
  background-color: rgba(241, 238, 247, 0.3);
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #f9e6ed;
}

.table-hover .table-primary:hover {
  background-color: #b7cded;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b7cded;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #dddfe2;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c9fde7;
}

.table-hover .table-success:hover {
  background-color: #c5e7a4;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c5e7a4;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #cbe7fb;
}

.table-hover .table-info:hover {
  background-color: #b3dcf9;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3dcf9;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fbeebc;
}

.table-hover .table-warning:hover {
  background-color: #fae8a4;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fae8a4;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f1c1c0;
}

.table-hover .table-danger:hover {
  background-color: #ecacab;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ecacab;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.04);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.04);
}

.table .thead-dark th,
.text-wrap table .thead-dark th {
  color: #e9ecf3;
  background-color: #a7b4c9;
  border-color: #32383e;
}

.table .thead-light th,
.text-wrap table .thead-light th {
  color: #3d4e67;
  background-color: #e8ebf3;
  border-color: #e8ebf3;
}

.table-dark {
  color: #e9ecf3;
  background-color: #a7b4c9;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered,
.text-wrap table.table-dark {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered,
  .text-wrap .table-responsive-sm > table {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered,
  .text-wrap .table-responsive-md > table {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered,
  .text-wrap .table-responsive-lg > table {
    border: 0;
  }
}

@media (max-width: 1279.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered,
  .text-wrap .table-responsive-xl > table {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered,
.text-wrap .table-responsive > table {
  border: 0;
}

table {
  border-collapse: collapse;
}

table.dataTable tfoot td,
table.dataTable tfoot th {
  border-top: 0;
}

table.dataTable thead td,
table.dataTable thead th {
  border-bottom: 0;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #e8ebf3;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  margin: 0;
  border: 0;
}

table.dataTable.display tbody tr:hover,
table.dataTable.hover tbody tr:hover {
  background-color: #eeeef9;
}

.table thead th,
.text-wrap table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.table th,
.text-wrap table th {
  color: #070510;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
}

.table-md td,
.table-md th {
  padding: 0.5rem;
}

.table-vcenter td,
.table-vcenter th {
  vertical-align: middle;
  border-top: 1px solid #e8ebf3;
}

.table-center td,
.table-center th {
  text-align: center;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: 0 0;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(241, 238, 247, 0.3);
}

.table-calendar {
  margin: 0 0 0.75rem;
}

.table-calendar td,
.table-calendar th {
  border: 0;
  text-align: center;
  padding: 0;
  width: 14.28571429%;
  line-height: 2.5rem;
}

.table-calendar td {
  border-top: 0;
}

.table-calendar-link {
  line-height: 2rem;
  min-width: calc(2rem + 2px);
  display: inline-block;
  border-radius: 3px;
  background: #f8f9fa;
  color: #3d4e67;
  font-weight: 600;
  transition: 0.3s background, 0.3s color;
  position: relative;
}

.table-calendar-link:before {
  content: '';
  width: 4px;
  height: 4px;
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  border-radius: 50px;
  background: #467fcf;
}

.table-calendar-link:hover {
  color: #fff;
  text-decoration: none;
  background: #467fcf;
  transition: 0.3s background;
}

.table-calendar-link:hover:before {
  background: #fff;
}

.table-header {
  cursor: pointer;
  transition: 0.3s color;
}

.table-header:hover {
  color: #3d4e67;
}

.table-header:after {
  content: '\f0dc';
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.table-header-asc {
  color: #3d4e67;
}

.table-header-asc:after {
  content: '\f0de';
}

.table-header-desc {
  color: #3d4e67;
}

.table-header-desc:after {
  content: '\f0dd';
}

.snackbar {
  visibility: hidden;
  opacity: 0;
  min-width: 120px;
  margin-left: -125px;
  background-color: #fff;
  color: #1e242f;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeef9;
  border-bottom: 3px solid #000;
  border-radius: 2px;
  padding: 10px;
  position: fixed;
  z-index: 9999;
  right: 2%;
  top: 30px;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.snackbar.show {
  visibility: visible;
  opacity: 1;
  transform: translateX(0%);
}

.nav-toggle {
  position: relative;
  padding: 10px 0;
  background: transparent;
  border: 1px solid transparent;
  margin: 7px 0;
  cursor: pointer;
}

.nav-toggle:focus {
  outline-width: 0;
}

.nav-toggle [class*='bar-'] {
  background: transparent;
  display: block;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;

  border-radius: 2px;
  height: 2px;
  margin-bottom: 4px;
}

.nav-toggle .bar-bot {
  margin-bottom: 0;
}

.opened .bar-top {
  display: block;
}

.opened .bar-mid {
  display: block;
}
.opened .bar-bot {
  display: block;
}

ins.adsbygoogle[data-ad-status='unfilled'] {
  display: none;
}

.yarl__thumbnails_thumbnail_active {
  border: 2px solid white;
}

@media only screen and (max-width: 1024px) {
  .custom-grid-3-col {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .grid-5-col {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .mobile-list-border-only::after,
  .mobile-list-border-only::before {
    content: '';
    position: absolute;
    background-color: var(--line-color);
    z-index: 1;
  }

  .mobile-list-border-only::after {
    inline-size: var(--line-length);
    block-size: var(--line-thickness);
    inset-inline-start: calc((100% - var(--line-length)) / 2);
    inset-block-start: calc(var(--line-offset) * -1);
  }

  .mobile-list-border-only::before {
    --line-length: 100%;
    inline-size: var(--line-thickness);
    block-size: var(--line-length);
    inset-inline-start: calc(var(--line-offset) * -1);
  }
}

@media only screen and (max-width: 1048px) {
  .nav-toggle .bar-top {
    width: 25px;
    background: #1e242f;
  }

  .nav-toggle .bar-mid {
    width: 60%;
    background: #1e242f;
  }

  .nav-toggle .bar-bot {
    width: 40%;
    background: #1e242f;
  }

  .opened .bar-top {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 15% 15%;
    transform-origin: 15% 15%;
  }

  .opened .bar-mid {
    width: 100%;
    opacity: 0;
  }

  .opened .bar-bot {
    width: 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 15% 95%;
    transform-origin: 15% 95%;
  }

  .mobile-collapse {
    --timing: cubic-bezier(0.32, 0.74, 0.57, 1);
    --subnav-bg-from: #fff;
    --indicator-bg-to: #2c2cf8;
    --subnav-color: #282828;

    z-index: 0;
    display: flex;
    visibility: hidden;
    overflow: hidden;
    flex-grow: 1;
    height: 0;
    transition: opacity 0.52s var(--timing);
    opacity: 0;
    color: var(--submbnav-color, var(--subnav-color));
    background: linear-gradient(
      to right,
      var(--submbnav-bg-from, var(--subnav-bg-from)) 0%,
      var(--submbnav-bg-to, var(--subnav-bg-to)) 100%
    );
  }

  .collapse-activated {
    visibility: visible;
    flex-grow: 1;
    height: auto;
    opacity: 1;
    z-index: 999;
    border-color: transparent;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    margin-block-start: 0.625rem;
    margin-block-end: 1.25rem;
  }

  .pattern-dot:before {
    --start-position: -3%;
  }

  .nav-item,
  .nav-item-header {
    padding: 5px 10px;
  }

  .nav-ani:hover {
    background-color: transparent;
  }

  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
  }

  .grid-5-col {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .custom-list-border::after,
  .custom-grid-border::after {
    display: block;
    inline-size: var(--line-length);
    block-size: var(--line-thickness);
    inset-inline-start: calc((100% - var(--line-length)) / 2);
    inset-block-start: calc(var(--line-offset) * -1);
  }

  .list {
    --gap: 2rem;
    gap: var(--gap);
  }

  .text-sm {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-responsive-md {
    font-size: 0.9375rem;
    line-height: 1.40625rem;
  }

  .text-base {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .custom-text-base {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .text-xl {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .custom-text-xl {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .text-3xl {
    font-size: 1.75rem;
    line-height: 1.875rem;
  }

  .text-4xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .text-5xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .body-wrapper {
    padding-top: 20px;
  }

  .desktop-list-border-only::after,
  .desktop-list-border-only::before {
    display: none;
  }

  .float-show {
    box-shadow: rgba(232, 235, 243, 1) 0px -2.5px 2px 0px;
    bottom: 0;
    height: auto;
    padding-top: 8px;
  }
}

@media only screen and (max-width: 730px) {
  .table-width-15 {
    width: 25%;
  }
}

@media only screen and (max-width: 470px) {
  .table-width-15 {
    width: 30%;
  }
}

.youtube-thumbnail {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.youtube-thumbnail img {
  width: 100%;
  height: auto;
}

.youtube-thumbnail iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-thumbnail.hidden {
  display: none;
}

.iframe-placeholder {
  background-color: rgb(209, 209, 209);
}

.center-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.search-button {
  width: 100px;
  height: 100px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.search-button::before {
  content: '';
  position: absolute;
  top: 22.5%;
  right: 23.5%;
  width: 0;
  height: 4px;
  margin-top: -2px;
  background-color: #000;
  transform: rotate(-45deg);
  transform-origin: right top;
}
.search-button::after {
  content: '';
  position: absolute;
  bottom: 22.5%;
  right: 23.5%;
  width: 30%;
  height: 4px;
  margin-top: -2px;
  background-color: #000;
  transform: rotate(45deg);
  transform-origin: right bottom;
}
.search-icon {
  position: absolute;
  display: block;
  top: 20%;
  left: 20%;
  width: 35%;
  height: 35%;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  border-color: #000;
}
.search-button.active::before,
.search-button.active::after {
  width: 77.5%;
}
.search-button.active .search-icon {
  width: 0;
  height: 0;
  border-color: transparent;
}
.search-button::before,
.search-button::after,
.search-button .search-icon {
  transition: all 0.3s ease-in-out;
}

.lightbox::before {
  content: 'X';
  cursor: pointer;
  font-size: 32px;
  right: 19px;
  top: 8px;
  font-weight: 600;
  position: fixed;
  display: block;
  text-align: right;
  color: #fff;
  z-index: 9999;
}

amp-social-share[type='system'] {
  outline: 0px;
  filter: invert(100%);
}

amp-social-share[type='system'] :hover {
  outline: 0px;
  color: blue;
}

.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

.scrollable-content {
  overflow-x: auto;
  display: block;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}

.section-title-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 20px;
}

.section-title-text {
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 576px) {
  /* Small screens (up to 'sm') */
}

@media (max-width: 768px) {
  /* Medium screens (up to 'md') */
  .section-title-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  .section-title-text {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  /* Large screens (up to 'lg') */
}

@media (max-width: 1440px) {
  /* Extra-large screens (up to 'xl') */
}
